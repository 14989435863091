import {
  DirectoryFragment,
  DirectoryType,
} from '../../../../graphql/generated';
import {
  SupportedDirectoryType,
  supportedDirectoryTypes,
} from '../interfaces/supported-directory-type';

export const getSupportedDirectoryType = (
  directory: DirectoryFragment,
): SupportedDirectoryType | undefined => {
  if (
    (supportedDirectoryTypes as readonly DirectoryType[]).includes(
      directory.type,
    )
  ) {
    return directory.type as SupportedDirectoryType;
  }
};
