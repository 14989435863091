import React, { useEffect, useState } from 'react';
import { Portal_AuditLogEventsQuery } from '../../../../graphql/generated';
import { graphql } from '../../../utils/graphql';

export const AuditLogs = () => {
  const [auditLogEvents, setAuditLogEvents] =
    useState<Portal_AuditLogEventsQuery['portal_auditLogEvents']['data']>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const reloadAuditLogEvents = async (): Promise<void> => {
      if (!isLoaded) {
        const response = await graphql().portal_auditLogEvents();

        if (response?.data?.portal_auditLogEvents) {
          setAuditLogEvents(response.data.portal_auditLogEvents.data);
          setIsLoaded(true);
        }
      }
    };

    void reloadAuditLogEvents();
  }, [isLoaded, setIsLoaded]);

  if (auditLogEvents) {
    return (
      <div>
        <div style={{ marginBottom: 20 }}>this is auditLogEvents</div>

        <div>
          <pre>{JSON.stringify(auditLogEvents, null, 2)}</pre>
        </div>
      </div>
    );
  }

  if (isLoaded) {
    return <div>we're loading isLoaded</div>;
  }

  return <div>auditLogEvents undefined yo</div>;
};
