import React, { useEffect, VFC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const AuditLogsRedirector: VFC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/audit-logs/environment-id'); // TODO:
  }, [navigate]);

  return <Outlet />;
};
