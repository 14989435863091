import React, { VFC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { AuditLogs } from '../pages/audit-logs';
import { AuditLogsRedirector } from './audit-logs-redirector';

interface AuditLogsRouterProps {}

export const AuditLogsRouter: VFC<Readonly<AuditLogsRouterProps>> = () => (
  <>
    <Topnav />

    <Routes>
      <Route element={<AuditLogsRedirector />} path="/">
        <Route element={<AuditLogs />} path="/:environmentId" />
      </Route>
    </Routes>
  </>
);
