import { useAdapterStatus } from '@flopflip/react-broadcast';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { useFeatureFlagContext } from '../../components/feature-flag-provider/feature-flag-provider';
import { StoreProvider } from '../../components/store-provider';
import {
  ConnectionFragment as Connection,
  DirectoryCustomAttributeFragment as DirectoryCustomAttribute,
  DirectoryFragment as Directory,
  PortalSessionFragment as PortalSession,
} from '../../graphql/generated';
import { useFeature } from '../../utils/feature-flags';
import { setPrimaryColorCssVariable } from '../../utils/set-primary-color-variables';

type PageProps = {
  connection?: Connection;
  directory?: Directory;
  directoryCustomAttributes?: DirectoryCustomAttribute[];
  session?: PortalSession;
};

export const Page: FC<Readonly<PageProps>> = ({
  connection,
  directory,
  directoryCustomAttributes,
  session,
  children,
}) => {
  const { setAppName } = useFeatureFlagContext();
  const hidePoweredByWorkos = useFeature('hidePoweredByWorkos');
  const appName = session?.appName || '';
  const { isReady } = useAdapterStatus();
  const router = useRouter();
  const [title, setTitle] = useState('Admin Portal');

  useEffect(() => {
    if (appName) {
      setAppName(appName);
    }
  }, [appName, setAppName]);

  useEffect(() => {
    if (session?.portalSettings.primaryColor) {
      setPrimaryColorCssVariable(session.portalSettings.primaryColor);
    }
  }, [session]);

  useEffect(() => {
    if (isReady && router.isReady && session) {
      const titleBrand = !hidePoweredByWorkos ? 'WorkOS' : session?.appName;
      setTitle(`${titleBrand} Admin Portal`);
    }
  }, [session, router, isReady, hidePoweredByWorkos]);

  return (
    <StoreProvider
      appName={appName}
      connection={connection}
      directory={directory}
      directoryCustomAttributes={directoryCustomAttributes}
      successUrl={session?.successUrl || null}
    >
      <>
        <Head>
          <title>{title}</title>
          <meta content="width=640" name="viewport" />

          <link
            href={
              session?.portalSettings.faviconUrl
                ? session?.portalSettings.faviconUrl
                : 'https://workos.imgix.net/brand/v2/favicon@32x32.png'
            }
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link
            href={
              session?.portalSettings.faviconUrl
                ? session?.portalSettings.faviconUrl
                : 'https://workos.imgix.net/brand/v2/favicon@256x256.png'
            }
            rel="apple-touch-icon"
          />

          <link
            as="style"
            href="https://fonts.googleapis.com/css?family=Inter:400,500,700&display=auto"
            rel="preload"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Inter:400,500,700&display=auto"
            rel="stylesheet"
          />
        </Head>

        {children}
      </>
    </StoreProvider>
  );
};
