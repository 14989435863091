import React, { FC } from 'react';
import { CenteredPageContent } from '../../../../components/centered-page-content';
import { DirectoryOverview } from './directory-overview';

interface ConfiguredDirectoryPageProps {}

export const ConfiguredDirectoryPage: FC<
  Readonly<ConfiguredDirectoryPageProps>
> = () => (
  <CenteredPageContent>
    <DirectoryOverview />
  </CenteredPageContent>
);
